@font-face {
    font-family: 'wemolo';
    src: url('./wemolo.eot?aj83e0');
    src:
        url('./wemolo.eot?aj83e0#iefix') format('embedded-opentype'),
        url('./wemolo.ttf?aj83e0') format('truetype'),
        url('./wemolo.woff?aj83e0') format('woff'),
        url('./wemolo.svg?aj83e0#wemolo') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='wemolo-'],
[class*=' wemolo-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'wemolo' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.wemolo-confetti:before {
    content: '\e900';
}
.wemolo-info-circle:before {
    content: '\e901';
}
.wemolo-arrow-right:before {
    content: '\e902';
}
.wemolo-bank:before {
    content: '\e903';
}
.wemolo-be-bold:before {
    content: '\e904';
}
.wemolo-be-excellent:before {
    content: '\e905';
}
.wemolo-blacklist:before {
    content: '\e906';
}
.wemolo-book-1:before {
    content: '\e907';
}
.wemolo-building-shopping-mall:before {
    content: '\e908';
}
.wemolo-building-skyscraper:before {
    content: '\e909';
}
.wemolo-bulp-1:before {
    content: '\e90a';
}
.wemolo-bus:before {
    content: '\e90b';
}
.wemolo-bycicle:before {
    content: '\e90c';
}
.wemolo-calendar:before {
    content: '\e90d';
}
.wemolo-calendar-check:before {
    content: '\e90e';
}
.wemolo-camera:before {
    content: '\e90f';
}
.wemolo-cantina:before {
    content: '\e910';
}
.wemolo-car-add:before {
    content: '\e911';
}
.wemolo-caravan:before {
    content: '\e912';
}
.wemolo-card-1:before {
    content: '\e913';
}
.wemolo-car-delete:before {
    content: '\e914';
}
.wemolo-car-exit:before {
    content: '\e915';
}
.wemolo-car-flow:before {
    content: '\e916';
}
.wemolo-car-medium:before {
    content: '\e917';
}
.wemolo-cart-1:before {
    content: '\e918';
}
.wemolo-car-van:before {
    content: '\e919';
}
.wemolo-cash-2:before {
    content: '\e91a';
}
.wemolo-certificate:before {
    content: '\e91b';
}
.wemolo-check:before {
    content: '\e91c';
}
.wemolo-check-circle:before {
    content: '\e91d';
}
.wemolo-chevron-down:before {
    content: '\e91e';
}
.wemolo-coffee-break:before {
    content: '\e91f';
}
.wemolo-cross:before {
    content: '\e920';
}
.wemolo-cross-circle:before {
    content: '\e921';
}
.wemolo-data-analysis:before {
    content: '\e922';
}
.wemolo-document-check:before {
    content: '\e923';
}
.wemolo-document-cross:before {
    content: '\e924';
}
.wemolo-download:before {
    content: '\e925';
}
.wemolo-electric-station:before {
    content: '\e926';
}
.wemolo-external-link:before {
    content: '\e927';
}
.wemolo-extra-lot:before {
    content: '\e928';
}
.wemolo-eye-closed:before {
    content: '\e929';
}
.wemolo-eye-open:before {
    content: '\e92a';
}
.wemolo-filters-1:before {
    content: '\e92b';
}
.wemolo-flag-2:before {
    content: '\e92c';
}
.wemolo-food:before {
    content: '\e92d';
}
.wemolo-glasses:before {
    content: '\e92e';
}
.wemolo-globe:before {
    content: '\e92f';
}
.wemolo-growth:before {
    content: '\e930';
}
.wemolo-grow-together:before {
    content: '\e931';
}
.wemolo-gym:before {
    content: '\e932';
}
.wemolo-headphones:before {
    content: '\e933';
}
.wemolo-heart:before {
    content: '\e934';
}
.wemolo-home:before {
    content: '\e935';
}
.wemolo-hourglass:before {
    content: '\e936';
}
.wemolo-ico-trash:before {
    content: '\e937';
}
.wemolo-image-1:before {
    content: '\e938';
}
.wemolo-info-triangle:before {
    content: '\e939';
}
.wemolo-kundenportal:before {
    content: '\e93a';
}
.wemolo-licens-plate:before {
    content: '\e93b';
}
.wemolo-location-2:before {
    content: '\e93c';
}
.wemolo-lock-off:before {
    content: '\e93d';
}
.wemolo-lock-on:before {
    content: '\e93e';
}
.wemolo-login:before {
    content: '\e93f';
}
.wemolo-login-1:before {
    content: '\e940';
}
.wemolo-mail-open:before {
    content: '\e941';
}
.wemolo-map-1:before {
    content: '\e942';
}
.wemolo-menu-hamburger:before {
    content: '\e943';
}
.wemolo-message-circle-dots:before {
    content: '\e944';
}
.wemolo-message-conversation:before {
    content: '\e945';
}
.wemolo-mood-smile:before {
    content: '\e946';
}
.wemolo-nightparking:before {
    content: '\e947';
}
.wemolo-outside:before {
    content: '\e948';
}
.wemolo-parkdepot:before {
    content: '\e949';
}
.wemolo-parking-house:before {
    content: '\e94a';
}
.wemolo-parking:before {
    content: '\e94b';
}
.wemolo-parking-lots:before {
    content: '\e94c';
}
.wemolo-parking-ok:before {
    content: '\e94d';
}
.wemolo-party:before {
    content: '\e94e';
}
.wemolo-phone-call:before {
    content: '\e94f';
}
.wemolo-plus:before {
    content: '\e950';
}
.wemolo-price:before {
    content: '\e951';
}
.wemolo-printer:before {
    content: '\e952';
}
.wemolo-refresh:before {
    content: '\e953';
}
.wemolo-rotate-left:before {
    content: '\e954';
}
.wemolo-scan:before {
    content: '\e955';
}
.wemolo-scan-code:before {
    content: '\e956';
}
.wemolo-scan-license:before {
    content: '\e957';
}
.wemolo-search:before {
    content: '\e958';
}
.wemolo-settings:before {
    content: '\e959';
}
.wemolo-signage:before {
    content: '\e95a';
}
.wemolo-star-1:before {
    content: '\e95b';
}
.wemolo-stopwatch:before {
    content: '\e95c';
}
.wemolo-tag:before {
    content: '\e95d';
}
.wemolo-terminal:before {
    content: '\e95e';
}
.wemolo-terminal-back:before {
    content: '\e95f';
}
.wemolo-ticket-1:before {
    content: '\e960';
}
.wemolo-truck:before {
    content: '\e961';
}
.wemolo-umbrella:before {
    content: '\e962';
}
.wemolo-user:before {
    content: '\e963';
}
.wemolo-user-add:before {
    content: '\e964';
}
.wemolo-user-management:before {
    content: '\e965';
}
.wemolo-user-remove:before {
    content: '\e966';
}
.wemolo-users:before {
    content: '\e967';
}
.wemolo-vacation:before {
    content: '\e968';
}
.wemolo-warning-circle:before {
    content: '\e969';
}
.wemolo-web:before {
    content: '\e96a';
}
.wemolo-arrow-left:before {
    content: '\e96b';
}
.wemolo-chevron-left:before {
    content: '\e96c';
}
.wemolo-chevron-right:before {
    content: '\e96d';
}
.wemolo-logout:before {
    content: '\e96e';
}
.wemolo-chevron-up:before {
    content: '\e96f';
}
.wemolo-arrow-down:before {
    content: '\e970';
}
.wemolo-car-kombi:before {
    content: '\e971';
}
.wemolo-car-bus:before {
    content: '\e972';
}
.wemolo-car-suv:before {
    content: '\e973';
}
.wemolo-car-trailer:before {
    content: '\e974';
}
.wemolo-car-small:before {
    content: '\e975';
}
.wemolo-camping-car:before {
    content: '\e976';
}
.wemolo-subscription:before {
    content: '\e977';
}
.wemolo-wemolon:before {
    content: '\e978';
}
.wemolo-mood-smile-round:before {
    content: '\e979';
}
.wemolo-mood-empty:before {
    content: '\e97a';
}
.wemolo-mood-sad:before {
    content: '\e97b';
}
.wemolo-mood-happy:before {
    content: '\e97c';
}
