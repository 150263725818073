@font-face {
    font-family: 'pkd';
    src: url('./pkd.eot?lr7o70');
    src:
        url('./pkd.eot?lr7o70#iefix') format('embedded-opentype'),
        url('./pkd.ttf?lr7o70') format('truetype'),
        url('./pkd.woff?lr7o70') format('woff'),
        url('./pkd.svg?lr7o70#pkd') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='pkd-'],
[class*=' pkd-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'pkd' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.pkd-contactless:before {
    content: '\e900';
}
.pkd-ice-copyright:before {
    content: '\e901';
}
.pkd-law:before {
    content: '\e902';
}
.pkd-accessible:before {
    content: '\e903';
}
.pkd-alarm:before {
    content: '\e904';
}
.pkd-arrow-circle-down:before {
    content: '\e905';
}
.pkd-arrow-circle-left:before {
    content: '\e906';
}
.pkd-arrow-circle-right:before {
    content: '\e907';
}
.pkd-arrow-circle-up:before {
    content: '\e908';
}
.pkd-arrow-down:before {
    content: '\e909';
}
.pkd-arrow-left:before {
    content: '\e90a';
}
.pkd-arrow-right:before {
    content: '\e90b';
}
.pkd-arrow-small:before {
    content: '\e90c';
}
.pkd-arrow-small-down:before {
    content: '\e90d';
}
.pkd-arrow-small-left:before {
    content: '\e90e';
}
.pkd-arrow-small-right:before {
    content: '\e90f';
}
.pkd-arrows-maximize:before {
    content: '\e910';
}
.pkd-arrows-maximize-1:before {
    content: '\e911';
}
.pkd-arrow-up:before {
    content: '\e912';
}
.pkd-at-email:before {
    content: '\e913';
}
.pkd-attachement:before {
    content: '\e914';
}
.pkd-award:before {
    content: '\e915';
}
.pkd-battery-1:before {
    content: '\e916';
}
.pkd-battery-2:before {
    content: '\e917';
}
.pkd-battery-3:before {
    content: '\e918';
}
.pkd-battery-4:before {
    content: '\e919';
}
.pkd-bell:before {
    content: '\e91a';
}
.pkd-bell-disabled:before {
    content: '\e91b';
}
.pkd-bell-ring:before {
    content: '\e91c';
}
.pkd-blackblist:before {
    content: '\e91d';
}
.pkd-block-1:before {
    content: '\e91e';
}
.pkd-block-2:before {
    content: '\e91f';
}
.pkd-bolt:before {
    content: '\e920';
}
.pkd-book-1:before {
    content: '\e921';
}
.pkd-book-2:before {
    content: '\e922';
}
.pkd-bookmark:before {
    content: '\e923';
}
.pkd-brand-android:before {
    content: '\e924';
}
.pkd-brand-apple:before {
    content: '\e925';
}
.pkd-brand-appstore:before {
    content: '\e926';
}
.pkd-brand-chrome:before {
    content: '\e927';
}
.pkd-brand-facebook:before {
    content: '\e928';
}
.pkd-brand-firefox:before {
    content: '\e929';
}
.pkd-brand-gmail:before {
    content: '\e92a';
}
.pkd-brand-google:before {
    content: '\e92b';
}
.pkd-brand-google-play:before {
    content: '\e92c';
}
.pkd-brand-instagram:before {
    content: '\e92d';
}
.pkd-brand-linkedin:before {
    content: '\e92e';
}
.pkd-brand-telegram:before {
    content: '\e92f';
}
.pkd-brand-twitter:before {
    content: '\e930';
}
.pkd-brand-whatsapp:before {
    content: '\e931';
}
.pkd-building:before {
    content: '\e932';
}
.pkd-building-community:before {
    content: '\e933';
}
.pkd-building-skyscraper:before {
    content: '\e934';
}
.pkd-bulp-1:before {
    content: '\e935';
}
.pkd-bulp-2:before {
    content: '\e936';
}
.pkd-bus:before {
    content: '\e937';
}
.pkd-business:before {
    content: '\e938';
}
.pkd-calendar:before {
    content: '\e939';
}
.pkd-calendar-clock:before {
    content: '\e93a';
}
.pkd-calendar-cross:before {
    content: '\e93b';
}
.pkd-calendar-edit:before {
    content: '\e93c';
}
.pkd-calendar-link:before {
    content: '\e93d';
}
.pkd-calendar-lock:before {
    content: '\e93e';
}
.pkd-calendar-minus:before {
    content: '\e93f';
}
.pkd-calendar-plus:before {
    content: '\e940';
}
.pkd-calendar-user:before {
    content: '\e941';
}
.pkd-calendar-warning:before {
    content: '\e942';
}
.pkd-calnedar-check:before {
    content: '\e943';
}
.pkd-cam:before {
    content: '\e944';
}
.pkd-camdisabled:before {
    content: '\e945';
}
.pkd-camera:before {
    content: '\e946';
}
.pkd-camera-disabled:before {
    content: '\e947';
}
.pkd-car-add:before {
    content: '\e948';
}
.pkd-caravan:before {
    content: '\e949';
}
.pkd-car-crash-1:before {
    content: '\e94a';
}
.pkd-car-crash-2:before {
    content: '\e94b';
}
.pkd-card-1:before {
    content: '\e94c';
}
.pkd-card-2:before {
    content: '\e94d';
}
.pkd-card-add:before {
    content: '\e94e';
}
.pkd-card-check:before {
    content: '\e94f';
}
.pkd-card-credit:before {
    content: '\e950';
}
.pkd-card-delete:before {
    content: '\e951';
}
.pkd-car-delete:before {
    content: '\e952';
}
.pkd-cardiology:before {
    content: '\e953';
}
.pkd-car-large:before {
    content: '\e954';
}
.pkd-car-medium:before {
    content: '\e955';
}
.pkd-car-small:before {
    content: '\e956';
}
.pkd-car-tall:before {
    content: '\e957';
}
.pkd-cash-2:before {
    content: '\e958';
}
.pkd-cash-3:before {
    content: '\e959';
}
.pkd-cast:before {
    content: '\e95a';
}
.pkd-charts:before {
    content: '\e95b';
}
.pkd-check:before {
    content: '\e95c';
}
.pkd-check-circle:before {
    content: '\e95d';
}
.pkd-check-small:before {
    content: '\e95e';
}
.pkd-check-square:before {
    content: '\e95f';
}
.pkd-chevron-circle-down:before {
    content: '\e960';
}
.pkd-chevron-circle-left:before {
    content: '\e961';
}
.pkd-chevron-circle-right:before {
    content: '\e962';
}
.pkd-chevron-circle-up:before {
    content: '\e963';
}
.pkd-chevron-down:before {
    content: '\e964';
}
.pkd-chevron-left:before {
    content: '\e965';
}
.pkd-chevron-right:before {
    content: '\e966';
}
.pkd-chevron-small-down:before {
    content: '\e967';
}
.pkd-chevron-small-left:before {
    content: '\e968';
}
.pkd-chevron-small-right:before {
    content: '\e969';
}
.pkd-chevron-small-up:before {
    content: '\e96a';
}
.pkd-chevron-up:before {
    content: '\e96b';
}
.pkd-clock:before {
    content: '\e96c';
}
.pkd-cloud:before {
    content: '\e96d';
}
.pkd-compass:before {
    content: '\e96e';
}
.pkd-confetti:before {
    content: '\e96f';
}
.pkd-cookie:before {
    content: '\e970';
}
.pkd-copy:before {
    content: '\e971';
}
.pkd-cross:before {
    content: '\e972';
}
.pkd-cross-circle:before {
    content: '\e973';
}
.pkd-cross-small:before {
    content: '\e974';
}
.pkd-data-analysis:before {
    content: '\e975';
}
.pkd-delete:before {
    content: '\e976';
}
.pkd-direction-board:before {
    content: '\e977';
}
.pkd-direction-board-left:before {
    content: '\e978';
}
.pkd-direction-board-right:before {
    content: '\e979';
}
.pkd-disabled:before {
    content: '\e97a';
}
.pkd-discount:before {
    content: '\e97b';
}
.pkd-discount-2:before {
    content: '\e97c';
}
.pkd-dislike:before {
    content: '\e97d';
}
.pkd-dna:before {
    content: '\e97e';
}
.pkd-document-check:before {
    content: '\e97f';
}
.pkd-document-clean:before {
    content: '\e980';
}
.pkd-document-cross:before {
    content: '\e981';
}
.pkd-document-filled:before {
    content: '\e982';
}
.pkd-document-minus:before {
    content: '\e983';
}
.pkd-document-plus:before {
    content: '\e984';
}
.pkd-download:before {
    content: '\e985';
}
.pkd-e-car:before {
    content: '\e986';
}
.pkd-edit-1:before {
    content: '\e987';
}
.pkd-edit-2:before {
    content: '\e988';
}
.pkd-edit-3:before {
    content: '\e989';
}
.pkd-edit-4:before {
    content: '\e98a';
}
.pkd-education:before {
    content: '\e98b';
}
.pkd-electric-station:before {
    content: '\e98c';
}
.pkd-employee:before {
    content: '\e98d';
}
.pkd-euro:before {
    content: '\e98e';
}
.pkd-euro-double:before {
    content: '\e98f';
}
.pkd-europe:before {
    content: '\e990';
}
.pkd-eye-closed:before {
    content: '\e991';
}
.pkd-eye-open:before {
    content: '\e992';
}
.pkd-faq:before {
    content: '\e993';
}
.pkd-female:before {
    content: '\e994';
}
.pkd-filter-1:before {
    content: '\e995';
}
.pkd-filter-2:before {
    content: '\e996';
}
.pkd-filter-3:before {
    content: '\e997';
}
.pkd-fingerprint:before {
    content: '\e998';
}
.pkd-firetruck:before {
    content: '\e999';
}
.pkd-flag-1:before {
    content: '\e99a';
}
.pkd-flag-2:before {
    content: '\e99b';
}
.pkd-flag-3:before {
    content: '\e99c';
}
.pkd-flag-4:before {
    content: '\e99d';
}
.pkd-folder:before {
    content: '\e99e';
}
.pkd-forward:before {
    content: '\e99f';
}
.pkd-fullscreen:before {
    content: '\e9a0';
}
.pkd-fullscreen-collaps:before {
    content: '\e9a1';
}
.pkd-gas-station:before {
    content: '\e9a2';
}
.pkd-globe:before {
    content: '\e9a3';
}
.pkd-ground-sensor:before {
    content: '\e9a4';
}
.pkd-ground-sensors:before {
    content: '\e9a5';
}
.pkd-headphones:before {
    content: '\e9a6';
}
.pkd-heart:before {
    content: '\e9a7';
}
.pkd-home:before {
    content: '\e9a8';
}
.pkd-home-1:before {
    content: '\e9a9';
}
.pkd-home-2:before {
    content: '\e9aa';
}
.pkd-home-hospital:before {
    content: '\e9ab';
}
.pkd-horizontal:before {
    content: '\e9ac';
}
.pkd-hourglass:before {
    content: '\e9ad';
}
.pkd-housing-estate:before {
    content: '\e9ae';
}
.pkd-ice:before {
    content: '\e9af';
}
.pkd-image-1:before {
    content: '\e9b0';
}
.pkd-inbox:before {
    content: '\e9b1';
}
.pkd-infinity:before {
    content: '\e9b2';
}
.pkd-info:before {
    content: '\e9b3';
}
.pkd-info-circle:before {
    content: '\e9b4';
}
.pkd-info-small:before {
    content: '\e9b5';
}
.pkd-info-triangle:before {
    content: '\e9b6';
}
.pkd-installment:before {
    content: '\e9b7';
}
.pkd-key:before {
    content: '\e9b8';
}
.pkd-kundenportal:before {
    content: '\e9b9';
}
.pkd-laptop:before {
    content: '\e9ba';
}
.pkd-licens-plate:before {
    content: '\e9bb';
}
.pkd-like:before {
    content: '\e9bc';
}
.pkd-link:before {
    content: '\e9bd';
}
.pkd-list:before {
    content: '\e9be';
}
.pkd-location-1:before {
    content: '\e9bf';
}
.pkd-location-2:before {
    content: '\e9c0';
}
.pkd-location-3:before {
    content: '\e9c1';
}
.pkd-location-add:before {
    content: '\e9c2';
}
.pkd-location-caution:before {
    content: '\e9c3';
}
.pkd-location-check:before {
    content: '\e9c4';
}
.pkd-location-check-1:before {
    content: '\e9c5';
}
.pkd-location-remove:before {
    content: '\e9c6';
}
.pkd-lock-off:before {
    content: '\e9c7';
}
.pkd-lock-on:before {
    content: '\e9c8';
}
.pkd-login:before {
    content: '\e9c9';
}
.pkd-logout:before {
    content: '\e9ca';
}
.pkd-mail:before {
    content: '\e9cb';
}
.pkd-mailbox:before {
    content: '\e9cc';
}
.pkd-mail-open:before {
    content: '\e9cd';
}
.pkd-male:before {
    content: '\e9ce';
}
.pkd-map-1:before {
    content: '\e9cf';
}
.pkd-marker:before {
    content: '\e9d0';
}
.pkd-marker-1:before {
    content: '\e9d1';
}
.pkd-menu-hamburger:before {
    content: '\e9d2';
}
.pkd-menu-horizontal:before {
    content: '\e9d3';
}
.pkd-menu-vertical:before {
    content: '\e9d4';
}
.pkd-message-circle:before {
    content: '\e9d5';
}
.pkd-message-circle-dots:before {
    content: '\e9d6';
}
.pkd-message-circle-lines:before {
    content: '\e9d7';
}
.pkd-message-conversation:before {
    content: '\e9d8';
}
.pkd-message-square:before {
    content: '\e9d9';
}
.pkd-message-square-dots:before {
    content: '\e9da';
}
.pkd-message-square-lines:before {
    content: '\e9db';
}
.pkd-microphone:before {
    content: '\e9dc';
}
.pkd-microphone-disabled:before {
    content: '\e9dd';
}
.pkd-minus:before {
    content: '\e9de';
}
.pkd-minus-circle:before {
    content: '\e9df';
}
.pkd-minus-circle-1:before {
    content: '\e9e0';
}
.pkd-minus-small:before {
    content: '\e9e1';
}
.pkd-mood-confuzed:before {
    content: '\e9e2';
}
.pkd-mood-crazy-happy:before {
    content: '\e9e3';
}
.pkd-mood-cry:before {
    content: '\e9e4';
}
.pkd-mood-empty:before {
    content: '\e9e5';
}
.pkd-mood-happy:before {
    content: '\e9e6';
}
.pkd-mood-nervous:before {
    content: '\e9e7';
}
.pkd-mood-neutral:before {
    content: '\e9e8';
}
.pkd-mood-sad:before {
    content: '\e9e9';
}
.pkd-mood-smile:before {
    content: '\e9ea';
}
.pkd-mood-surprised:before {
    content: '\e9eb';
}
.pkd-mood-tongue:before {
    content: '\e9ec';
}
.pkd-moon:before {
    content: '\e9ed';
}
.pkd-motorbike:before {
    content: '\e9ee';
}
.pkd-music:before {
    content: '\e9ef';
}
.pkd-navigation:before {
    content: '\e9f0';
}
.pkd-newscreen:before {
    content: '\e9f1';
}
.pkd-next:before {
    content: '\e9f2';
}
.pkd-outside:before {
    content: '\e9f3';
}
.pkd-parkdepot:before {
    content: '\e9f4';
}
.pkd-parking:before {
    content: '\e9f5';
}
.pkd-parking-guard:before {
    content: '\e9f6';
}
.pkd-parking-house:before {
    content: '\e9f7';
}
.pkd-pause:before {
    content: '\e9f8';
}
.pkd-pay-later:before {
    content: '\e9f9';
}
.pkd-pay-online:before {
    content: '\e9fa';
}
.pkd-paypal:before {
    content: '\e9fb';
}
.pkd-pay-terminal:before {
    content: '\e9fc';
}
.pkd-phone:before {
    content: '\e9fd';
}
.pkd-phone-call:before {
    content: '\e9fe';
}
.pkd-phone-correct:before {
    content: '\e9ff';
}
.pkd-phone-cross:before {
    content: '\ea00';
}
.pkd-phone-down:before {
    content: '\ea01';
}
.pkd-pie-chart-1:before {
    content: '\ea02';
}
.pkd-pie-chart-2:before {
    content: '\ea03';
}
.pkd-pie-chart-3:before {
    content: '\ea04';
}
.pkd-pinpaper-check:before {
    content: '\ea05';
}
.pkd-pinpaper-cross:before {
    content: '\ea06';
}
.pkd-pinpaper-filled:before {
    content: '\ea07';
}
.pkd-pinpaper-minus:before {
    content: '\ea08';
}
.pkd-pinpaper-plus:before {
    content: '\ea09';
}
.pkd-plane-departure:before {
    content: '\ea0a';
}
.pkd-play:before {
    content: '\ea0b';
}
.pkd-plus:before {
    content: '\ea0c';
}
.pkd-plus-circle:before {
    content: '\ea0d';
}
.pkd-plus-small:before {
    content: '\ea0e';
}
.pkd-power:before {
    content: '\ea0f';
}
.pkd-presentation:before {
    content: '\ea10';
}
.pkd-previous:before {
    content: '\ea11';
}
.pkd-printer:before {
    content: '\ea12';
}
.pkd-progress:before {
    content: '\ea13';
}
.pkd-question:before {
    content: '\ea14';
}
.pkd-question-circle:before {
    content: '\ea15';
}
.pkd-question-small:before {
    content: '\ea16';
}
.pkd-real-estate:before {
    content: '\ea17';
}
.pkd-redo:before {
    content: '\ea18';
}
.pkd-redo-circle:before {
    content: '\ea19';
}
.pkd-redo-small:before {
    content: '\ea1a';
}
.pkd-refreshcircle:before {
    content: '\ea1b';
}
.pkd-refresh:before {
    content: '\ea1c';
}
.pkd-refresh-circle:before {
    content: '\ea1d';
}
.pkd-refresh-small:before {
    content: '\ea1e';
}
.pkd-reset-mail:before {
    content: '\ea1f';
}
.pkd-reset-password:before {
    content: '\ea20';
}
.pkd-resize-circle-horizontal:before {
    content: '\ea21';
}
.pkd-resize-circle-vertical:before {
    content: '\ea22';
}
.pkd-resize-small-horizontal:before {
    content: '\ea23';
}
.pkd-resize-small-vertical:before {
    content: '\ea24';
}
.pkd-restaurant:before {
    content: '\ea25';
}
.pkd-rewind:before {
    content: '\ea26';
}
.pkd-robot-1:before {
    content: '\ea27';
}
.pkd-robot-2:before {
    content: '\ea28';
}
.pkd-rocket:before {
    content: '\ea29';
}
.pkd-rotate-circle-left:before {
    content: '\ea2a';
}
.pkd-rotate-circle-right:before {
    content: '\ea2b';
}
.pkd-rotate-left:before {
    content: '\ea2c';
}
.pkd-rotate-right:before {
    content: '\ea2d';
}
.pkd-rotate-small-left:before {
    content: '\ea2e';
}
.pkd-rotate-small-right:before {
    content: '\ea2f';
}
.pkd-save:before {
    content: '\ea30';
}
.pkd-screen:before {
    content: '\ea31';
}
.pkd-screen-disabled:before {
    content: '\ea32';
}
.pkd-screen-share:before {
    content: '\ea33';
}
.pkd-search:before {
    content: '\ea34';
}
.pkd-search-paper:before {
    content: '\ea35';
}
.pkd-search-parking:before {
    content: '\ea36';
}
.pkd-send-1:before {
    content: '\ea37';
}
.pkd-send-2:before {
    content: '\ea38';
}
.pkd-settings:before {
    content: '\ea39';
}
.pkd-share-1:before {
    content: '\ea3a';
}
.pkd-share-2:before {
    content: '\ea3b';
}
.pkd-shield-check:before {
    content: '\ea3c';
}
.pkd-shield-cross:before {
    content: '\ea3d';
}
.pkd-shield-empty:before {
    content: '\ea3e';
}
.pkd-signboard:before {
    content: '\ea3f';
}
.pkd-smartphone:before {
    content: '\ea40';
}
.pkd-spacious:before {
    content: '\ea41';
}
.pkd-speaker-0:before {
    content: '\ea42';
}
.pkd-speaker-1:before {
    content: '\ea43';
}
.pkd-speaker-2:before {
    content: '\ea44';
}
.pkd-speaker-cross:before {
    content: '\ea45';
}
.pkd-speaker-disabled:before {
    content: '\ea46';
}
.pkd-star-1:before {
    content: '\ea47';
}
.pkd-star-2:before {
    content: '\ea48';
}
.pkd-stop:before {
    content: '\ea49';
}
.pkd-stopwatch:before {
    content: '\ea4a';
}
.pkd-sun:before {
    content: '\ea4b';
}
.pkd-tag:before {
    content: '\ea4c';
}
.pkd-ticket-1:before {
    content: '\ea4d';
}
.pkd-ticket-2:before {
    content: '\ea4e';
}
.pkd-ticket-paid:before {
    content: '\ea4f';
}
.pkd-tire:before {
    content: '\ea50';
}
.pkd-traffic-cone:before {
    content: '\ea51';
}
.pkd-traffic-lights:before {
    content: '\ea52';
}
.pkd-trash:before {
    content: '\ea53';
}
.pkd-trending-down:before {
    content: '\ea54';
}
.pkd-trending-up:before {
    content: '\ea55';
}
.pkd-trophy:before {
    content: '\ea56';
}
.pkd-truck:before {
    content: '\ea57';
}
.pkd-umbrella:before {
    content: '\ea58';
}
.pkd-umbrella-1:before {
    content: '\ea59';
}
.pkd-undo:before {
    content: '\ea5a';
}
.pkd-undo-circle:before {
    content: '\ea5b';
}
.pkd-undo-small:before {
    content: '\ea5c';
}
.pkd-user:before {
    content: '\ea5d';
}
.pkd-user-add:before {
    content: '\ea5e';
}
.pkd-user-check:before {
    content: '\ea5f';
}
.pkd-user-circle:before {
    content: '\ea60';
}
.pkd-user-close:before {
    content: '\ea61';
}
.pkd-user-remove:before {
    content: '\ea62';
}
.pkd-users:before {
    content: '\ea63';
}
.pkd-users-group:before {
    content: '\ea64';
}
.pkd-user-small:before {
    content: '\ea65';
}
.pkd-vertical:before {
    content: '\ea66';
}
.pkd-verwaltung:before {
    content: '\ea67';
}
.pkd-virus:before {
    content: '\ea68';
}
.pkd-walk:before {
    content: '\ea69';
}
.pkd-wallet:before {
    content: '\ea6a';
}
.pkd-wallet-1:before {
    content: '\ea6b';
}
.pkd-warning:before {
    content: '\ea6c';
}
.pkd-warning-circle:before {
    content: '\ea6d';
}
.pkd-warning-small:before {
    content: '\ea6e';
}
.pkd-waterdrop:before {
    content: '\ea6f';
}
.pkd-wheelchair:before {
    content: '\ea70';
}
.pkd-wifi:before {
    content: '\ea71';
}
.pkd-wifi-off:before {
    content: '\ea72';
}
.pkd-wifi-problem:before {
    content: '\ea73';
}
.pkd-wind:before {
    content: '\ea74';
}
.pkd-windows:before {
    content: '\ea75';
}
.pkd-zoom-in:before {
    content: '\ea76';
}
.pkd-zoom-out:before {
    content: '\ea77';
}
.pkd-cash-4:before {
    content: '\ea78';
}
.pkd-creditcard:before {
    content: '\ea79';
}
.pkd-pay-later-thin:before {
    content: '\ea7a';
}
.pkd-bank:before {
    content: '\ea7b';
}
.pkd-car-van:before {
    content: '\ea7c';
}
.pkd-car-camper:before {
    content: '\ea7d';
}
.pkd-car-no-found:before {
    content: '\ea7e';
}
.pkd-owl-nightparking:before {
    content: '\ea7f';
}
.pkd-night-parking:before {
    content: '\ea80';
}
