*,
*::after,
*::before {
    box-sizing: border-box;
}

html.unscrollable {
    -webkit-overflow-scrolling: touch !important;
    overflow: auto !important;
    height: 100% !important;
}

html.unscrollable > body {
    height: 100% !important;
    -webkit-overflow-scrolling: touch !important;
    overflow: auto !important;
}

body {
    margin: 0px;
    font-family: 'Albert Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overscroll-behavior: none;
    overflow: hidden;
}

body.loading {
    background: rgb(38, 51, 64);
    background: -moz-radial-gradient(circle, rgba(38, 51, 64, 1) 70%, rgba(26, 34, 41, 1) 100%);
    background: -webkit-radial-gradient(circle, rgba(38, 51, 64, 1) 70%, rgba(26, 34, 41, 1) 100%);
    background: radial-gradient(circle, rgba(38, 51, 64, 1) 70%, rgba(26, 34, 41, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#263340",endColorstr="#1a2229",GradientType=1);
}

body.scrolling {
    overflow: auto;
    margin: 0px;
}

#root {
    height: var(--vh, 100vh);
    background: rgb(38, 51, 64);
    background: -moz-radial-gradient(circle, rgba(38, 51, 64, 1) 70%, rgba(26, 34, 41, 1) 100%);
    background: -webkit-radial-gradient(circle, rgba(38, 51, 64, 1) 70%, rgba(26, 34, 41, 1) 100%);
    background: radial-gradient(circle, rgba(38, 51, 64, 1) 70%, rgba(26, 34, 41, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#263340",endColorstr="#1a2229",GradientType=1);
}

.scrolling #root {
    height: initial;
    margin: 0px;
}

#root > div:first-child {
    position: relative;
    z-index: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.fadeIn {
    animation: 0.5s ease-out 0s 1 fadeIn;
}

.lock-scroll .ReactVirtualized__Grid {
    overflow-y: hidden !important;
}

@keyframes fadeIn {
    0% {
        opacity: 0.1;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.hg-button.backspace {
    color: red;
    width: 50px;
}

.hg-button.all-buttons {
    font-size: 19px;
    height: 40px;
    margin-top: 1px;
    margin-bottom: 1px;
}

.hg-button.hg-standardBtn.all-buttons.numbers {
    background-color: #cccccc;
}

@media screen and (min-width: 425px) {
    .hg-button.all-buttons {
        font-size: 24px;
    }

    .hg-button.all-buttons {
        height: 50px;
    }
}

@media screen and (min-width: 769px) {
    .hg-button.all-buttons {
        font-size: 28px;
    }

    .hg-button.all-buttons {
        height: 60px;
    }
}

@media screen and (min-width: 769px) and (max-width: 1080px) {
    .react-simple-keyboard {
        padding: 30px 64px;
    }

    .react-simple-keyboard.hg-theme-default .hg-row .hg-button:not(:last-child) {
        margin-right: 12px;
    }

    .react-simple-keyboard.hg-theme-default .hg-row {
        justify-content: center;
    }

    .hg-button.hg-standardBtn.all-buttons.letters {
        width: 60px;
    }

    .hg-button.hg-standardBtn.all-buttons.numbers {
        width: 60px;
    }

    .hg-button.hg-standardBtn.all-buttons.hg-special {
        width: 60px;
    }

    .hg-button.hg-special {
        width: 60px;
    }

    .hg-button.backspace {
        width: 220px;
    }

    .react-simple-keyboard.hg-theme-default .hg-button {
        flex-grow: 0;
    }
}

@media screen and (min-width: 1081px) {
    .react-simple-keyboard {
        padding: 37px 98px;
    }

    .react-simple-keyboard.hg-theme-default .hg-row .hg-button:not(:last-child) {
        margin-right: 12px;
    }

    .react-simple-keyboard.hg-theme-default .hg-button {
        flex-grow: 0;
    }

    .hg-button.hg-standardBtn.all-buttons.letters {
        width: 140px;
    }

    .hg-button.hg-standardBtn.all-buttons.numbers {
        width: 140px;
    }

    .hg-button.hg-standardBtn.all-buttons.hg-special {
        width: 140px;
    }

    .react-simple-keyboard.hg-theme-default .hg-row {
        justify-content: center;
    }

    .hg-button.backspace {
        width: 220px;
    }
}
